.start {
  &-device-label {
    font-size: 1.6rem;
    font-weight: 600;
    color: #132e5b;
    height: 88px;

    @media (max-width: 991px) {
      font-size: 1.3rem;
    }
  }

  &-welcome-helpicon {
    position: absolute;
    right: 20px;
    border: 3px solid transparent;
    padding: 0 3px 0 5px;
    border-radius: 15px;

    &:hover {
      border-color: var(--primary);
      cursor: pointer;
    }
  }
}

.clickable-img {
  padding: 0.5rem 0.25rem;
  background-color: #fff;
  border-radius: 0.25rem;
  height: 92px;
  border: 1px solid #c1c1c1;
  background-color: #f7f7f7;
  box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.09);
  
  &:hover {
    opacity: 0.9;
    cursor: pointer;
    box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.3);
  }

  & img {
    max-width: 100%;
    max-height: 100%;
  }

  &-divider {
    text-align: center;
  }
}

.img-thumbnail {
  border: none !important;
}