body {
  min-width: 830px;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  margin-bottom: 10%;
}

a:hover {
  text-decoration: none !important;
}

.card {
  max-width: 1100px;
  margin: auto;
  box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.08);
}

.opacity-50 {
  opacity: 0.5;
}

$theme-colors: (
  "primary": #2254aa,
  "primary-dark": #1c448a,
  "primary-darker": #002e7e,
);

@import '~bootstrap/scss/bootstrap.scss';

.custom-control-input:checked ~ .custom-control-label::before,
.btn-primary {
  background-color: var(--primary);
  border-color: var(--primary);
  color: var(--white);
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle,
.btn-primary:focus, .btn-primary.focus,
.btn-primary:hover {
  background-color: var(--primary-darker);
  border-color: var(--primary-darker);
  color: var(--white);
}

a {
  color: var(--primary);
}

.tooltip-inner {
  box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.75);
  -webkit-box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.75);
}

.card-body {
  box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.01);
}

.alert-icon {
  color: rgb(216, 1, 1);
}

@keyframes append-animate {
  from {
    transform: scaleY(0);
    opacity: 0;
  }
  to {
    transform: scaleY(1);
    opacity: 1;
  }
}

.append-animate {
  transform-origin: 50% 0;
  animation: append-animate .3s linear;
}

// MM:     #2254aa, dark: #1c448a, darker: #002e7e
// Sontex: #f7ad49, dark: #e69146, darker: #b06827