.accordion>.card {
  overflow: visible;
}

.accordion .row {
  align-content: center;
}

.accordion {
  box-shadow: 0 0 3px rgba(185, 184, 184, 0.45);

  & .form-control {
    height: 22px;
    padding: 0 5px;
    cursor: pointer;
    box-shadow: 0 0 3px rgba(185, 184, 184, 0.45);
  }

  & .input-group-text {
    height: 22px;
    width: 60px;
  }
}

.card-header {
  cursor: pointer;
  font-weight: 600;

  &-tabs {
    margin: 0;
    border-bottom: 1px solid #8f9193;
  }

  &:hover {
    background:
      #eee;
  }
}

.svg-inline--fa {
  margin-right: 3px;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  border-color: #8f9193 #8f9193 #fff !important;
  font-weight: 600;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #8f9193 #8f9193 #8f9193;
}

.tab-icon {
  height: 18px;
  margin-right: 10px;
}

.copy {
  &-button {
    opacity: 0.75;
    background-color: #eee;
    position: absolute;
    right: 25%;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
    height: 30px;
    line-height: 28px;

    &__dark {
      background-color: #343a40;
      border-color: #343a40;

      &:hover {
        background-color: #343a40;
        border-color: #343a40;
      }
    }

    &:hover {
      opacity: 1;
      background-color: #eee;
    }

    & .btn.btn-primary {
      line-height: 28px;
      height: 30px;
      padding: 0 7px;
    }
  }

  &-success {
    cursor: default;
    opacity: 0.95;
    padding: 5px 10px;
  }
}

.ok-button {
  height: 30px;
  line-height: 30px;
  padding: 0 8px;
}

.active-config-button {
  position: absolute;
  height: 26px;
  line-height: 26px;
  padding: 0 6px;
  font-size: 14px;
  margin-top: -1px;
  border: 1px solid #aaa;
  opacity: 0.8;
}

.reset-button {
  width: 126px;
}

.walkby-button {
  left: 152px;
  width: 76px;
}

.stationary-button {
  left: 234px;
  width: 86px;
}

.btn {
  box-shadow: 0 0 3px rgba(57, 57, 57, 0.45);
}

.modal {
  &-button {

    &-load {
      right: 15px;
      position: absolute;
      margin-top: -3px;
      height: 30px;
      line-height: 28px;
      padding: 0 10px;
    }

    &-code {
      left: 50%;
      transform: translate(-50%, 0);
      position: absolute;
      margin-top: -3px;
      height: 30px;
      line-height: 28px;
      padding: 0 10px;
    }
  }

  &-dialog {
    max-width: 830px !important;
  }

  &-device {
    margin-right: 20px !important;
  }
}

.end-date-big {
  opacity: 0.4;

  &:hover {
    opacity: 1;
  }
}

.battery-lifetime {
  box-shadow: none !important;
  & .col-4 {
    margin-top: unset;
  }
}

.config-code-success {
  background-color: #befabe;
  border-radius: 4px;
}

#pdfCompany,
#pdfProject {
  height: 30px;
}

.valid-input {
  box-shadow: 0 0px 3px 3px rgba(0, 255, 22, 0.3);
  background-color: #f8fff9 !important;
  border-color: #9df4ad !important;
}

.invalid-input {
  box-shadow: 0 0px 3px 3px rgba(255, 0, 0, 0.3);
  background-color: #fbeeee !important;
  border-color: #f49d9d !important;
}

.generate-random-button {
  height: 22px;
  width: 22px;
  padding: 0 4px;
  position: absolute;
  right: 30px;
  top: 2px;
  background-clip: padding-box;
  border: 1px solid #b0b1b3;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-shadow: 0 0 3px rgba(185, 184, 184, 0.45);
  font-size: 14px;
}

/** SWITCH */
.custom-control-label {
  cursor: pointer;
}

.expert-switch {
  display: inline-block;
  margin-right: 20px;
}

.setting {
  &__label {
    display: inline-block;
    cursor: help;

    &:hover {
      opacity: 0.7;
    }
  }

  &__accordion {

    &__radio-protocol {
      position: absolute;
      margin-left: 2px;
      font-size: 0.9rem;
      color: #c7c7c7;
      line-height: 1.9;

      &:hover {
        color: #333;
      }
    }

    &__indicator {
      position: absolute;
      right: 30px;
    }

    &__icon {
      margin: 4px 10px;
    }
  }

  &__select {
    &__timezone {
      width: 100%;
      z-index: 999;
    }
  }

  &-row {
    padding-left: 15px;
    padding-right: 15px;
    min-height: 40px;
    padding-top: 8px;

    &:nth-child(2n) {
      background-color: #f4f4f4;
    }
  }
}

.text-input {
  width: 82%;
  display: inline-block;
  font-family: Courier New;
  padding-top: 1px !important;
}

.hash-input {
  height: 30px;
  font-family: Courier New;
  padding: 0;
  text-align: center;
  width: 320px;
  margin: auto;
}

.hash-input-short {
  height: 30px;
  width: 150px;
  position: relative;
  left: 50%;
  transform: translate(-60%, 0);
  padding: 0;
  text-align: center;
  font-family: Courier New;
  padding-top: 1px !important;
}


.expert-mode-btn.btn.btn-outline-secondary {
  cursor: pointer;
  margin: -2px 16px;
  position: absolute;
  z-index: 2;
  line-height: 15px;
  color: #b1b1b1;
  border-color: #b1b1b1;

  &:hover {
    color: white;
  }
}

/** TIMEZONE SELECT */
input.jsx-4179805763 {
  cursor: pointer;
  font-size: 1rem !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  padding: 0 5px !important;
  height: 22px;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important;
}

button.jsx-4179805763 {
  background: #fff !important;

  &:hover {
    background: #eee !important;

  }
}

/** WEEKDAYS / MONTH SELECT */
.radioHours-selector input,
.weekDays-selector input,
.monthlyDays-selector input,
.values-selector input,
.month-selector input,
.battery-selector input {
  display: none !important;
}

.radioHours-selector input[type=checkbox]+label,
.weekDays-selector input[type=checkbox]+label,
.monthlyDays-selector input[type=checkbox]+label,
.values-selector input[type=checkbox]+label,
.month-selector input[type=checkbox]+label,
.battery-selector input[type=checkbox]+label {
  display: inline-block;
  border-radius: 4px;
  background: #dddddd;
  height: 25px;
  padding: 0px 5px;
  margin-right: 4px;
  min-width: 35px;
  line-height: 25px;
  text-align: center;
  cursor: pointer;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  transition: background 0.3s ease-in 0s, color 0.3ys ease-in 0s;

  &:hover {
    opacity: 0.75;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.8) !important;
  }
}

.values-selector {
  align-items: center !important;
  margin-top: 10px;
  margin-bottom: -5px;
}

.values-selector input[type=checkbox]+label {
  width: unset;
  padding: 0 8px;
  margin-right: 5px;
}

.battery-selector input[type=checkbox]+label {
  width: 55px;
}

.radioHours-selector input[type=checkbox]+label {
  width: 105px;
}

.radioHours-selector input[type=checkbox]:checked+label,
.weekDays-selector input[type=checkbox]:checked+label,
.monthlyDays-selector input[type=checkbox]:checked+label,
.values-selector input[type=checkbox]:checked+label,
.month-selector input[type=checkbox]:checked+label,
.battery-selector input[type=checkbox]:checked+label {
  background: var(--primary);
  color: #ffffff;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.55);
}

.monthlyDay-disabled {
  color: rgb(204, 204, 204) !important;
  background-color: var(--primary-darker) !important;
}

// Dual List

.rdl-filter, .rdl-control {
  padding: 4px 6px;
}

.react-dual-listbox {
  height: clamp(550px, 70vh, 1000px);
}

.progress {
  height: 1.3rem !important;
  font-size: 0.825rem !important;
  color: #000 !important;
  background-color: #f7f7f7 !important;
  box-shadow: 0 0 3px rgba(57, 57, 57, 0.45);
}